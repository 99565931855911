import { ApplicationsEnum } from '../enums/applications.enum';

export const AppPathsDictionary: Record<ApplicationsEnum, string> = {
    [ApplicationsEnum.CANDIDATE]: 'shell',
    [ApplicationsEnum.CANDIDATE_ONESTAFF]: '1ajme4yxtfgh',
    [ApplicationsEnum.CANDIDATE_TRN]: 'utcobgk49xfl',
    [ApplicationsEnum.CLIENT]: 'shell',
    [ApplicationsEnum.CLIENT_TRN]: 'fp925x1d3uotk',
    [ApplicationsEnum.CONSULTANT]: 'shell',
    [ApplicationsEnum.CONSULTANT_ONESTAFF]: 'lnfz27ok6ix0',
    [ApplicationsEnum.CONSULTANT_TRN]: '9ho5w1d3jnkf',
};
