import { ApplicationsEnum } from '../enums/applications.enum';

export const AppTitlesDictionary: Record<ApplicationsEnum, string> = {
    [ApplicationsEnum.CANDIDATE]: 'CANDIDATE',
    [ApplicationsEnum.CANDIDATE_ONESTAFF]: 'CANDIDATE',
    [ApplicationsEnum.CANDIDATE_TRN]: 'CANDIDATE',
    [ApplicationsEnum.CLIENT]: 'CLIENT',
    [ApplicationsEnum.CLIENT_TRN]: 'CLIENT',
    [ApplicationsEnum.CONSULTANT]: 'CONSULTANT',
    [ApplicationsEnum.CONSULTANT_ONESTAFF]: 'CONSULTANT',
    [ApplicationsEnum.CONSULTANT_TRN]: 'CONSULTANT',
};
